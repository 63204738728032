import React, { useState, useEffect } from 'react';
import { getQuestion, getQuestionSubmit } from '../../../api/security/index';
import SwipeableCard from './SwipeableCard';
import { StyledSeccion, StyledSectionContent } from './game.styles';
import { useLocation } from 'react-router-dom';

interface Icon {
  url: string;
}

interface HomeData {
  sure: number;
  attempts: number;
  points: number;
  avatar: string;
}

interface Fold {
  homeData: HomeData;
  backgroundCard: {
    url: string;
  };
  correctAnswers: {
    url: string;
  };
  incorrectAnswers: {
    url: string;
  };
  question: {
    questions: string;
    topic: string;
    answers: number | string;
    id: string;
    level: string;
  };
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
  alternativeText: string;
  caption: string;
}

interface MenuItem {
  iconOff: Icon;
  iconOn: Icon;
  page: {
    slug: string;
  };
}

interface Content {
  folds: Fold[];
  menu: MenuItem[];
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  backgroundPage: string;
  id: string;
  timeQuestion: string;
}

interface Data {
  content: Content[];
}

export const Game = () => {
  const [data, setData] = useState<Data | null>(null);
  const [insuranceCount, setInsuranceCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [menu, setMenu] = useState<Data | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const location = useLocation();
  const [timeLeft, setTimeLeft] = useState(120);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [initialTime, setInitialTime] = useState(120);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 780);
  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [questionId, setQuestionId] = useState<string | null>(null);
  const [showGame, setShowGame] = useState<boolean>(true);
   const [isAnimating, setIsAnimating] = useState(false);

  const currentPath = window.location.pathname.replace(/^\/+/, '');

  const ENCRYPTION_KEY = 'securitygame';

  const simpleEncrypt = (text: string): string => {
    return text
      .split('')
      .map((char) =>
        String.fromCharCode(char.charCodeAt(0) ^ ENCRYPTION_KEY.charCodeAt(0)),
      )
      .join('');
  };

  const simpleDecrypt = (encryptedText: string): string => {
    return simpleEncrypt(encryptedText); // La misma función sirve para encriptar y desencriptar
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ... (mantén los otros useEffect y funciones auxiliares)
  const clearLocalStorageForQuestion = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('timeLeft_') || key.startsWith('initialTime_')) {
        localStorage.removeItem(key);
      }
    });
    localStorage.removeItem('currentQuestionId');
  };


 const handleAnswer = async (isRight: boolean) => {
    if (questionId && initialTime !== null && timeLeft !== null) {
      const timeElapsed = initialTime - timeLeft;
      setIsAnimating(true);

      // Esperar a que la animación termine
      await new Promise(resolve => setTimeout(resolve, 500));

      setIsAnimating(false);
      setShowGame(false);
      setIsProcessing(true);
      await SubmitQuestion(questionId, isRight, Math.floor(timeElapsed), false);
      clearLocalStorageForQuestion();
    }
  };
  useEffect(() => {
    return () => {
      const questionId = data?.content[0]?.folds[0]?.question?.id;
      if (questionId) {
        clearLocalStorageForQuestion();
      }
    };
  }, [data]);

  useEffect(() => {
    if (redirectUrl && !isProcessing) {
      window.open(redirectUrl, '_self');
    }
  }, [redirectUrl, isProcessing]);

  useEffect(() => {
    const updateMyEndpointAndFetchQuestion = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const currentPath = location.pathname;

        const match = currentPath.match(/\/pregunta\/(\d+)/);
        if (!match) {
          throw new Error('No se pudo extraer el ID de la pregunta del path');
        }

        const newQuestionId = match[1];
        setQuestionId(newQuestionId);

        const tokenJwt = localStorage.getItem('myAccessToken') || '';
        if (!tokenJwt) {
          throw new Error('No se encontró el token de acceso');
        }

        const { data } = await getQuestion(tokenJwt, newQuestionId);

        setData(data);
        setInsuranceCount(data?.content[0]?.folds[0]?.homeData?.sure || 0);
        const newAttempts = data?.content[0]?.folds[0]?.homeData?.attempts || 0;
        setAttempts(newAttempts);
        setMenu(data?.content[0]?.menu);

        if (data?.content[0]?.folds[0]?.homeData?.attempts === 0) {
          setIsProcessing(true);
          // Aquí puedes agregar una llamada al backend si es necesario
          // await someBackendProcess();
          setIsProcessing(false);
        }

        const questionTime = parseInt(
          data?.content[0]?.timeQuestion || '120',
          10,
        );

        // Recuperar el questionId actual
        const currentQuestionId = localStorage.getItem('currentQuestionId');

        // Si el questionId ha cambiado, limpiar el localStorage del anterior
        if (currentQuestionId && currentQuestionId !== newQuestionId) {
          clearLocalStorageForQuestion();
        }

        // Guardar el nuevo questionId
        localStorage.setItem('currentQuestionId', newQuestionId);

        // Recuperar el tiempo guardado del localStorage
        const savedTimeEncrypted = localStorage.getItem(
          `timeLeft_${newQuestionId}`,
        );
        const savedInitialTimeEncrypted = localStorage.getItem(
          `initialTime_${newQuestionId}`,
        );

        if (savedTimeEncrypted && savedInitialTimeEncrypted) {
          const savedTime = parseInt(simpleDecrypt(savedTimeEncrypted), 10);
          const savedInitialTime = parseInt(
            simpleDecrypt(savedInitialTimeEncrypted),
            10,
          );

          if (!isNaN(savedTime) && !isNaN(savedInitialTime) && savedTime > 0) {
            setTimeLeft(savedTime);
            setInitialTime(savedInitialTime);
          } else {
            setTimeLeft(questionTime);
            setInitialTime(questionTime);
            localStorage.setItem(
              `timeLeft_${newQuestionId}`,
              simpleEncrypt(questionTime.toString()),
            );
            localStorage.setItem(
              `initialTime_${newQuestionId}`,
              simpleEncrypt(questionTime.toString()),
            );
          }
        } else {
          setTimeLeft(questionTime);
          setInitialTime(questionTime);
          localStorage.setItem(
            `timeLeft_${newQuestionId}`,
            simpleEncrypt(questionTime.toString()),
          );
          localStorage.setItem(
            `initialTime_${newQuestionId}`,
            simpleEncrypt(questionTime.toString()),
          );
        }
      } catch (error) {
        console.error('Error fetching question:', error);
        setError(
          error instanceof Error
            ? error.message
            : 'Error desconocido al cargar la pregunta',
        );
      } finally {
        setIsLoading(false);
        setShowGame(true);
      }
    };

    updateMyEndpointAndFetchQuestion();
  }, [location]);

  useEffect(() => {
    if (timeLeft !== null && timeLeft > 0 && questionId) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === null || prevTime <= 0) return 0;
          const newTime = prevTime - 1;
          // Save time to localStorage
          localStorage.setItem(
            `timeLeft_${questionId}`,
            simpleEncrypt(newTime.toString()),
          );
          return newTime;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0 && questionId && initialTime !== null) {
      handleAnswer(false);
    }
  }, [timeLeft, questionId, initialTime]);

  const getAttemptsImages = (attempts: number) => {
    const totalAttempts = 3;
    let images = [];
    for (let i = 0; i < totalAttempts; i++) {
      images.push(
        i < attempts
          ? `${base}/security/icon-heart-active.svg`
          : `${base}/security/icon-heart.svg`,
      );
    }
    return images;
  };

  const getInsuranceImages = (count: number) => {
    const totalInsurance = 3;
    let images = [];
    for (let i = 0; i < totalInsurance; i++) {
      images.push(
        i < count
          ? `${base}/security/icon-security-active.svg`
          : `${base}/security/icon-security.svg`,
      );
    }
    return images;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  };

  const SubmitQuestion = async (
    questionId: string,
    answer: boolean,
    time: number,
    isDemo: boolean,
  ) => {
    const tokenJwt = localStorage.getItem('myAccessToken') || '';
    try {
      const { data } = await getQuestionSubmit(
        tokenJwt,
        questionId,
        answer,
        time,
        isDemo,
      );

      if (data?.endpoint) {
        const myEndpoint = data.endpoint;
        localStorage.setItem('myEndpoint', myEndpoint);

        clearLocalStorageForQuestion();

        const fullUrl = `${window.location.origin}/${myEndpoint}`;
        // Usar setTimeout para asegurar que el loading se muestre por un tiempo mínimo
        setTimeout(() => {
          window.open(fullUrl, '_self');
        }, 500); // Ajusta este valor según sea necesario
      } else {
        setTimeout(() => {
          window.open(
            `${window.location.origin}/respuesta-incorrecta`,
            '_self',
          );
        }, 500);
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.error?.message || 'Error en el servicio';
      console.error(message);
      setTimeout(() => {
        window.open(`${window.location.origin}/respuesta-incorrecta`, '_self');
      }, 500);
    }
    // No establecemos setIsProcessing(false) aquí para mantener el loading hasta la redirección
  };
  // Agrega este nuevo useEffect justo después de tus otros useEffect
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundImage = isMobile ? '/BG_White_m.svg' : '/BG_white.svg';

  // Agrega este nuevo componente justo después del useEffect anterior
  const LoadingSpinner = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        backgroundImage: `url("${backgroundImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <img src="/loader_1.gif" alt="Loading..." />
    </div>
  );

  return (
    <>
     {(isLoading || isProcessing) && <LoadingSpinner />}
      {(showGame || isAnimating) && !isProcessing && (
        <StyledSeccion
          className="page game"
          bgColor={data?.content[0]?.backgroundPage}
        >
          <picture className="content__bg">
            <source
              srcSet={`${baseApi}${data?.content[0]?.backgroundImgPage?.url}`}
              media="(min-width: 780px)"
            />
            <img
              className="ranking__img"
              alt={data?.content[0]?.backgroundImgPageMobile?.alternativeText}
              title={data?.content[0]?.backgroundImgPageMobile?.caption}
              src={`${baseApi}${data?.content[0]?.backgroundImgPageMobile?.url}`}
            />
          </picture>
          <div className="content__header">
            <div className="content__header-container">
              <div className="content__header-content">
                <div className="content__header-top">
                  <div className="content__userinfo-item">
                    <div>
                      {getInsuranceImages(insuranceCount).map(
                        (imgSrc, index) => (
                          <img
                            key={index}
                            src={imgSrc}
                            alt={`Seguro ${index + 1}`}
                          />
                        ),
                      )}
                    </div>
                  </div>
                  <div className="content__userinfo-item">
                    <div>
                      {getAttemptsImages(attempts).map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`Intento ${index + 1}`}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="content__userinfo-item">
                    <div>
                      <img src={`${base}/security/icon-coin.svg`} alt="" />
                      <p className="content__userinfo-label">
                        {data?.content[0]?.folds[0]?.homeData?.points}
                      </p>
                    </div>
                  </div>
                  <div className="content__userinfo-item item-mobil">
                    <img
                      src={`${baseApi}${data?.content[0]?.folds[0]?.homeData?.avatar}`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="content__header-bottom">
                  <div className="content__header-bottom-one">
                    <p>{data?.content[0]?.folds[0]?.question?.topic}</p>
                    <p>
                      Pregunta{' '}
                      {(() => {
                        const answers =
                          data?.content[0]?.folds[0]?.question?.answers;
                        if (typeof answers === 'string') {
                          const [current, total] = answers
                            .split('/')
                            .map(Number);
                          if (!isNaN(current) && !isNaN(total)) {
                            return `${current + 1}/${total}`;
                          }
                        } else if (typeof answers === 'number') {
                          return `${answers + 1}/${answers + 1}`;
                        }
                        return '1/1'; // Valor por defecto si no hay datos válidos
                      })()}
                    </p>
                  </div>
                  <div className="content__header-bottom-two">
                    <p>Nivel {data?.content[0]?.folds[0]?.question?.level}</p>
                  </div>
                </div>
              </div>
              <div className="content__header-content-img">
                <img
                  src={`${baseApi}${data?.content[0]?.folds[0]?.homeData?.avatar}`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="content__game">
            {isMobile ? (
              <SwipeableCard
                content={data?.content[0]?.folds[0]?.question?.questions || ''}
                onSwipe={handleAnswer}
                backgroundImage={`${baseApi}${data?.content[0]?.folds[0]?.backgroundCard?.url}`}
                correctImageUrl={`${baseApi}${data?.content[0]?.folds[0]?.correctAnswers?.url}`}
                incorrectImageUrl={`${baseApi}${data?.content[0]?.folds[0]?.incorrectAnswers?.url}`}
              />
            ) : (
              <div className="content__game-container">
                <StyledSectionContent
                  className="content__game-content"
                  backgroundImage={`${baseApi}${data?.content[0]?.folds[0]?.backgroundCard?.url}`}
                >
                  <p>{data?.content[0]?.folds[0]?.question?.questions}</p>
                </StyledSectionContent>
                <div className="input-container correct">
                  <img
                    src={`${baseApi}${data?.content[0]?.folds[0]?.correctAnswers?.url}`}
                    alt="Input 1"
                    className="input-image"
                  />
                  <input
                    type="checkbox"
                    name="true"
                    className="input-field"
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleAnswer(true);
                      }
                    }}
                  />
                </div>
                <div className="input-container incorrect">
                  <img
                    src={`${baseApi}${data?.content[0]?.folds[0]?.incorrectAnswers?.url}`}
                    alt="Input 2"
                    className="input-image"
                  />
                  <input
                    type="checkbox"
                    name="false"
                    className="input-field"
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleAnswer(false);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="content__time">
              <p className="text-time">{formatTime(timeLeft)}</p>
            </div>
          </div>
          {menu && (
            <div className="menu">
              <div className="menu__container">
                <div className="menu__content">
                  {Array.isArray(menu) &&
                    menu?.map((item, index) => {
                      const isQuestionRoute = currentPath.includes(
                        'securitymatch/pregunta/',
                      );

                      // Identificar si este es el ítem del juego
                      const isGameItem =
                        item.displayName === 'Play Game' ||
                        item.page?.slug === 'securitymatch/nivel';

                      // Determinar si el icono debe estar activo
                      const isActive = isQuestionRoute && isGameItem;

                      return (
                        <div key={index}>
                          <img
                            src={`${baseApi}${
                              isActive ? item.iconOn?.url : item.iconOff?.url
                            }`}
                            alt={item.displayName || item.page?.slug}
                            className={`item-avatars-img ${
                              isActive ? 'active' : ''
                            }`}
                            onClick={() => {
                              if (item.page?.slug) {
                                const fullUrl = `${window.location.origin}/${item.page.slug}`;
                                window.open(fullUrl, '_self');
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </StyledSeccion>
      )}
    </>
  );
};
