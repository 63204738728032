import React, { useState, useEffect } from 'react';
import { getResult } from '../../../api/security/index';

import { StyledSeccion } from './result.styles';


import { useLocation } from 'react-router-dom';

interface Image {
  url: string;
}

interface Data {
  points: number;
  note: string;
}

interface Fold {
  title: string;
  image: Image;
  data: Data;
  labelBtn: string;
  message: string;
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
}

interface Content {
  backgroundPage: string;
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  folds: Fold[];
  endpoint: string;
}

interface ResultData {
  content: Content[];
}

const LoadingSpinner = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    <img src="/loader_1.gif" alt="Loading..." />
  </div>
);

export const Endlives = () => {
  const [resultData, setResultData] = useState<ResultData | null>(null);
  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;
  const [attempts, setAttempts] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

   useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // Intentar recuperar datos del sessionStorage
      const storedData = sessionStorage.getItem('endlivesData');
      if (storedData) {
        setResultData(JSON.parse(storedData));
        setIsLoading(false);
        return;
      }

      try {
        const token = localStorage.getItem('myAccessToken') || 'myAccessToken';
        const endpointResult = localStorage.getItem('myEndpoint') || 'n/a';
        const currentPath = location.pathname;
        let myEndpoint = currentPath.startsWith('/')
          ? currentPath.slice(1)
          : currentPath;
        myEndpoint = myEndpoint.startsWith('securitymatch/')
          ? myEndpoint
          : `securitymatch/${myEndpoint}`;

        const { data } = await getResult(token, endpointResult);
        console.log('Data received from Ranking:', data);

        if (data?.content?.[0]) {
          setResultData(data);
          // Guardar los datos en sessionStorage
          sessionStorage.setItem('endlivesData', JSON.stringify(data));
        }
        // Solo actualizar myEndpoint si el endpoint en la respuesta es diferente al actual
        if (
          data?.content[0]?.endpoint &&
          data.content[0].endpoint !== myEndpoint
        ) {
          const newEndpoint = data.content[0].endpoint.startsWith(
            'securitymatch/',
          )
            ? data.content[0].endpoint
            : `securitymatch/${data.content[0].endpoint}`;
          localStorage.setItem('myEndpoint', newEndpoint);
          console.log('Updated myEndpoint:', newEndpoint);
        }
      } catch (error) {
        console.error('Error de servicio', error);
        setError('An error occurred while fetching data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

    if (isLoading) {
    return <LoadingSpinner />;
  }


  const getAttemptsImages = (attempts: number) => {
    const totalAttempts = 3;
    let images = [];
    for (let i = 0; i < totalAttempts; i++) {
      images.push(
        i < attempts
          ? `${base}/security/icon-heart-active.svg`
          : `${base}/security/icon-heart.svg`,
      );
    }
    return images;}



   return (
    <>
      <StyledSeccion
        className="page correct"
        bgColor= {resultData?.content[0]?.backgroundPage ?? "fff"}
      >
          <picture className="content__bg">
          <source
            srcSet={`${baseApi}${resultData?.content[0]?.backgroundImgPage?.url}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            src={`${baseApi}${resultData?.content[0]?.backgroundImgPageMobile?.url}`}
          />
        </picture>
        <div className="page__container">
          <div className="content__info">
            <h2 className="title__result">
              {resultData?.content[0]?.folds[0]?.title}
            </h2>
            <div className="content-point">
              <img
                className="img-result"
                src={`${baseApi}${resultData?.content[0]?.folds[0]?.image?.url}`}
              />
            </div>
            <div className="content__heart">
              {getAttemptsImages(attempts).map((imgSrc, index) => (
                <img key={index} src={imgSrc} alt={`Intento ${index + 1}`} />
              ))}
            </div>
            <p
              className="alert__message"
              dangerouslySetInnerHTML={{
                __html: resultData?.content[0]?.folds[0]?.message ?? '',
              }}
            ></p>
            <button
              onClick={() => {
                if (resultData?.content[0]?.endpoint) {
                  const fullUrl = `${window.location.origin}/${resultData?.content[0]?.endpoint}`;
                  window.open(fullUrl, '_self');
                }
              }}
            >
              {resultData?.content[0]?.folds[0]?.labelBtn}
            </button>
          </div>
        </div>
      </StyledSeccion>
    </>
  );
};
