import React, { useState, useEffect, useRef } from 'react';
import { getResult } from '../../../api/security/index';
import { useLocation } from 'react-router-dom';

import { StyledSeccion } from './result.styles';

interface Image {
  url: string;
}

interface Data {
  points: number;
  note: string;
}

interface Fold {
  title: string;
  image: Image;
  data: Data;
  labelBtn: string;
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
}

interface Content {
  backgroundPage: string;
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  folds: Fold[];
  endpoint: string;
}

interface ResultData {
  content: Content[];
}

export const InCorrect = () => {
  const [resultData, setFetchedData] = useState<ResultData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const fetchResultData = async () => {
      setError(null);

      try {
        const token = localStorage.getItem('myAccessToken') || '';
        if (!token) {
          throw new Error('Token de acceso no encontrado');
        }

        // Usar la ruta actual para determinar el endpoint
        const currentPath = location.pathname;
        let myEndpoint = currentPath.startsWith('/')
          ? currentPath.slice(1)
          : currentPath;
        myEndpoint = myEndpoint.startsWith('securitymatch/')
          ? myEndpoint
          : `securitymatch/${myEndpoint}`;

        const { data } = await getResult(token, myEndpoint);

        setFetchedData(data);
        setAttempts(data?.content[0]?.folds[0]?.data?.attemps || 0);

        // Solo actualizar myEndpoint si el endpoint en la respuesta es diferente al actual
        if (
          data?.content[0]?.endpoint &&
          data.content[0].endpoint !== myEndpoint
        ) {
          const newEndpoint = data.content[0].endpoint.startsWith(
            'securitymatch/',
          )
            ? data.content[0].endpoint
            : `securitymatch/${data.content[0].endpoint}`;
          localStorage.setItem('myEndpoint', newEndpoint);
        }
      } catch (error) {
        console.error('Error fetching result:', error);
        setError(
          error instanceof Error
            ? error.message
            : 'Error desconocido al cargar el resultado',
        );
      }
    };

    fetchResultData();
  }, [location]);

  const getAttemptsImages = (attempts: number) => {
    const totalAttempts = 3;
    let images = [];
    for (let i = 0; i < totalAttempts; i++) {
      images.push(
        i < attempts
          ? `${base}/security/icon-heart-active.svg`
          : `${base}/security/icon-heart.svg`,
      );
    }
    return images;
  };

  return (
    <>
      <StyledSeccion
        className="page correct"
        bgColor={resultData?.content[0]?.backgroundPage ?? 'fff'}
      >
        <picture className="content__bg">
          <source
            srcSet={`${baseApi}${resultData?.content[0]?.backgroundImgPage?.url}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            src={`${baseApi}${resultData?.content[0]?.backgroundImgPageMobile?.url}`}
          />
        </picture>
        <div className="page__container">
          <div className="content__info">
            <h2 className="title__result">
              {resultData?.content[0]?.folds[0]?.title}
            </h2>
            <div className="content-point">
              <img
                className="img-result"
                src={`${baseApi}${resultData?.content[0]?.folds[0]?.image?.url}`}
              />
            </div>
            <div className="content__heart">
              {getAttemptsImages(attempts).map((imgSrc, index) => (
                <img key={index} src={imgSrc} alt={`Intento ${index + 1}`} />
              ))}
            </div>
            <p
              className="alert__message"
              dangerouslySetInnerHTML={{
                __html: resultData?.content[0]?.folds[0]?.data?.note ?? '',
              }}
            ></p>
            <button
              onClick={() => {
                if (resultData?.content[0]?.endpoint) {
                  const fullUrl = `${window.location.origin}/${resultData?.content[0]?.endpoint}`;
                  window.open(fullUrl, '_self');
                }
              }}
            >
              {resultData?.content[0]?.folds[0]?.labelBtn}
            </button>
          </div>
        </div>
      </StyledSeccion>
    </>
  );
};
