import React, { useState, useEffect, useRef } from 'react';

interface TourStep {
  selector: string;
  content: {
    labelstep: string;
    description: string;
    icon: {
      url: string;
    };
  };
  position?: 'top' | 'bottom' | 'left' | 'right';
}

interface TourProps {
  steps: TourStep[];
  onComplete: () => void;
  onStepChange: (step: number) => void;
}

const CustomTour: React.FC<TourProps> = ({
  steps,
  onComplete,
  onStepChange,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tourRef = useRef<HTMLDivElement>(null);

  const updatePosition = () => {
    if (currentStep < steps.length) {
      const element = document.querySelector(steps[currentStep].selector);
      if (element && tourRef.current) {
        const rect = element.getBoundingClientRect();
        const tooltipRect = tourRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let top = 0;
        let left = 0;

        switch (steps[currentStep].position) {
          case 'top':
            top = rect.top - tooltipRect.height - 10;
            left = rect.left + (rect.width - tooltipRect.width) / 2;
            break;
          case 'bottom':
            top = rect.bottom + 10;
            left = rect.left + (rect.width - tooltipRect.width) / 2;
            break;
          case 'left':
            top = rect.top + (rect.height - tooltipRect.height) / 2;
            left = rect.left - tooltipRect.width - 10;
            break;
          case 'right':
            top = rect.top + (rect.height - tooltipRect.height) / 2;
            left = rect.right + 10;
            break;
          default:
            top = rect.bottom + 10;
            left = rect.left + (rect.width - tooltipRect.width) / 2;
        }

        // Ajustes para mantener el tooltip dentro de la pantalla
        if (left < 0) left = 10;
        if (left + tooltipRect.width > viewportWidth)
          left = viewportWidth - tooltipRect.width - 10;
        if (top < 0) top = 10;
        if (top + tooltipRect.height > viewportHeight)
          top = viewportHeight - tooltipRect.height - 10;

        setPosition({ top, left });
      }
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [currentStep, steps]);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      onComplete();
    }
  };

  useEffect(() => {
    onStepChange(currentStep);
  }, [currentStep, onStepChange]);

  if (currentStep >= steps.length) return null;

  const { labelstep, description, icon } = steps[currentStep].content;

  return (
    <>
      <div
        className="tour-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}
      />
      <div
        ref={tourRef}
        className={`step-onboarding step-${currentStep + 1}`}
        style={{
          position: 'fixed',

          background: 'white',
          padding: '10px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
          zIndex: 999999,
          maxWidth: '90vw',
          width: '365px',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div className="step-onboarding-content">
          <img
            src={icon.url}
            alt={`Step ${currentStep + 1}`}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          <div className="step-onboarding-contenttext">
            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
              {labelstep}
            </span>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ fontSize: '0.9rem', lineHeight: '1.4' }}
            />
            <button
              onClick={nextStep}
              style={{
                padding: '8px 16px',
                fontSize: '0.9rem',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {currentStep < steps.length - 1 ? 'Siguiente' : 'Finalizar'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTour;
