import React, { useState, useEffect, useCallback } from 'react';
import { getTokenByAPI } from '../../../api/security/index';

export const Loading = () => {
  const [fadeOut, setFadeOut] = useState(false);
  const [progress, setProgress] = useState(0);

  const incrementProgress = useCallback(() => {
    setProgress((prev) => {
      if (prev >= 100) {
        return 100;
      } else if (prev >= 70) {
        setFadeOut(true);
      }
      // Incremento más suave, pero asegurando números enteros
      const increment = Math.max(1, Math.floor((100 - prev) / 10));
      return Math.min(100, prev + increment);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(incrementProgress, 100);

    return () => clearInterval(interval);
  }, [incrementProgress]);

  useEffect(() => {
    if (progress === 100) {
      // Aquí puedes agregar cualquier lógica adicional cuando el progreso llega al 100%
      console.log('Loading completo');
    }
  }, [progress]);

  const getColor = () => {
    if (progress >= 75) return '#29D36B';
    if (progress >= 40) return '#FFDF61';
    return '#E44058';
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken') || '';

    const processReferral = async () => {
      const queryString = window.location.search;

      if (queryString.includes('referred')) {
        const params = new URLSearchParams(queryString);
        const referred = params.get('referred');
        if (referred) {
          localStorage.setItem('referred', referred);
        }
      } else {
        localStorage.setItem('referred', '');
      }

      // Ahora que se ha procesado el parámetro 'referred', dispara el servicio
      try {
        const { data } = await getTokenByAPI(token);
        const { tokenJwt, endpoint, code, status } = data;

        localStorage.setItem('myAccessToken', tokenJwt);
        localStorage.setItem('myEndpoint', endpoint);
        localStorage.setItem('codeid', code);

        if (status === '200') {
          const pathfull = `${window.location.origin}/${endpoint}`;
          window.location.href = pathfull;
        } else {
          console.log('Error en el token:', data);
        }
      } catch (error) {
        console.error('Error de servicio', error);
      }
    };

    processReferral();
  }, []);

  return (
    <>
      <div className="page loading">
        <div className="content__bg">
          <img
            className="content__bg-main"
            src={`${process.env.PUBLIC_URL}/security/bg-white.png`}
            alt=""
          />
          <div className="content__bg-items">
            <img
              className={`item-hand hand--1 ${fadeOut ? 'fade-out-hand' : ''}`}
              src={`${process.env.PUBLIC_URL}/security/mano1.png`}
              alt=""
            />
            <img
              className={`item-hand hand--2 ${fadeOut ? 'fade-out-hand' : ''}`}
              src={`${process.env.PUBLIC_URL}/security/left-botton-mano.png`}
              alt=""
            />
            <img
              className={`item-hand hand--3 ${fadeOut ? 'fade-out-hand' : ''}`}
              src={`${process.env.PUBLIC_URL}/security/rigth-top-mano.png`}
              alt=""
            />
            <img
              className={`item-hand hand--4 ${fadeOut ? 'fade-out-hand' : ''}`}
              src={`${process.env.PUBLIC_URL}/security/mano4.png`}
              alt=""
            />
          </div>
        </div>
        <div className="page__container">
          <div className="content__info">
            <img
              className="img__loading"
              src={`${process.env.PUBLIC_URL}/security/Logo.png`}
              alt=""
            />
            <div className="content__progress">
              <div className="progress-bar">
                <div
                  className="progress-bar__fill"
                  style={{
                    width: `${progress}%`,
                    backgroundColor: getColor(),
                  }}
                />
              </div>
              <p>{progress}%</p>
            </div>
          </div>
        </div>
        <img
          className={`img-avatars ${fadeOut ? '' : 'fade-out'}`}
          src={`${process.env.PUBLIC_URL}/security/avatares.png`}
          alt=""
        />
      </div>
    </>
  );
};
