import React, { useState, useEffect } from 'react';
import { getUserZone } from '../../../api/security/index';

import { StyledTitle, StyledSubTitle } from './dashboard.styles';

// Define una interfaz para las propiedades de data
interface HomeData {
  sure: number;
  attempts: number;
  nickName: string;
  email: string;
  country: string;
  points: number;
  ranking: number;
  avatar: string;
  endgame?: boolean;
  menu: MenuItem[];
}

interface MenuItem {
  iconOff: {
    url: string;
  };
  iconOn: {
    url: string;
  };
}

interface UserPerformanceTopic {
  img: string;
  name: string;
  description: string;
  percent: number;
  color: string;
}

interface EvolutionData {
  levelName: string;
  avatars: { image: { url: string } }[];
}

interface Fold {
  homeData: HomeData;
  userPerformance: {
    topics: UserPerformanceTopic[];
    correctAnswer: number;
  };
  evolutionData: EvolutionData;
  menu: any;
  noGameMessage: string;
}

interface Content {
  folds: Fold[];
}

interface Data {
  content: Content[];
}

export const Dashboard = () => {
  const [data, setData] = useState<Data | null>(null);
  const [insuranceCount, setInsuranceCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [menu, setMenu] = useState<Data | null>(null);

  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const tokenJwt = localStorage.getItem('myAccessToken') || '';
    getUserZone(tokenJwt)
      .then(({ data }) => {
        setData(data);
        setInsuranceCount(data?.content[0]?.folds[0]?.homeData?.sure);
        setAttempts(data?.content[0]?.folds[0]?.homeData?.attempts);
        setMenu(data?.content[0]?.menu);
      })
      .catch(({ response: { data } }) => {
        const { error } = data;
        const message = error?.message || 'Error en el servicio';
      });
  }, []);

  const getAttemptsImages = (attempts: number) => {
    const totalAttempts = 3;
    let images = [];
    for (let i = 0; i < totalAttempts; i++) {
      images.push(
        i < attempts
          ? `${base}/security/icon-heart-active.svg`
          : `${base}/security/icon-heart.svg`,
      );
    }
    return images;
  };

  const getInsuranceImages = (count: number) => {
    const totalInsurance = 3;
    let images = [];
    for (let i = 0; i < totalInsurance; i++) {
      images.push(
        i < count
          ? `${base}/security/icon-security-active.svg`
          : `${base}/security/icon-security.svg`,
      );
    }
    return images;
  };

  const isEndGame = data?.content[0]?.folds[0]?.homeData?.endgame;
  const currentPath = window.location.pathname.replace(/^\/+/, '');

  return (
    <>
      <div className="page zone-user">
        <div className="content__bg">
          <img
            className="content__bg-main"
            src={`${base}/security/bg-white.png`}
            alt=""
          />
          <div className="content__bg-items"></div>
        </div>
        <div className="page__containerdashboard">
          <div className="content__userinfo">
            <div className="content__userinfo-contenttext">
              <img
                src={`${baseApi}${data?.content[0]?.folds[0]?.homeData?.avatar}`}
                alt=""
              />
              <div className="content__userinfo-content">
                <h2 className="content__userinfo-name">
                  Hola {data?.content[0]?.folds[0]?.homeData?.nickName}
                </h2>
                <p className="content__userinfo-email">
                  {data?.content[0]?.folds[0]?.homeData?.email}
                </p>
                <span className="content__userinfo-country">
                  Español | {data?.content[0]?.folds[0]?.homeData?.country}
                </span>
                <a
            className={`content__userinfo-cta ${isEndGame ? 'disabled' : ''}`}
  onClick={(e) => {
    if (isEndGame) {
      e.preventDefault();
    } else {
      window.location.href = '/securitymatch/nivel';
    }
  }}
  href={isEndGame ? undefined : '/securitymatch/nivel'}

                >
                  Jugar
                </a>
              </div>
            </div>
            <div className="content__userinfo-point">
              <div className="content__userinfo-item">
                <span>Ranking</span>
                <div>
                  <img src={`${base}/security/icon-copa.svg`} alt="" />
                  <p className="content__userinfo-label">
                    {data?.content[0]?.folds[0]?.homeData?.ranking}
                  </p>
                </div>
              </div>
              <div className="content__userinfo-item">
                <span>Puntos</span>
                <div>
                  <img src={`${base}/security/icon-coin.svg`} alt="" />
                  <p className="content__userinfo-label">
                    {data?.content[0]?.folds[0]?.homeData?.points}
                  </p>
                </div>
              </div>
              <div className="content__userinfo-item">
                <span>Seguros</span>
                <div>
                  {getInsuranceImages(insuranceCount).map((imgSrc, index) => (
                    <img key={index} src={imgSrc} alt={`Seguro ${index + 1}`} />
                  ))}
                </div>
              </div>
              <div className="content__userinfo-item">
                <span>Intentos</span>
                <div>
                  {getAttemptsImages(attempts).map((imgSrc, index) => (
                    <img
                      key={index}
                      src={imgSrc}
                      alt={`Intento ${index + 1}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="userprogress">
          <div className="userprogress__container">
            <div className="userprogress__box">
              <div className="userprogress__contenttext">
                <h2>Desempeño</h2>
                <p>
                  Has contestado{' '}
                  {data?.content[0]?.folds[0]?.userPerformance?.correctAnswer}{' '}
                  preguntas correctamente
                </p>
              </div>
              <div className="userprogress__content">
                <div className="userprogress__item">
                  <img
                    src={`${baseApi}${data?.content[0]?.folds[0]?.userPerformance?.topics[0]?.img}`}
                    alt=""
                    className="userprogress__item-img"
                  />
                  <div className="userprogress__item-container">
                    <div className="userprogress__item-content">
                      <StyledTitle
                        className="userprogress__title"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[0]
                            ?.color
                        }
                      >
                        {
                          data?.content[0]?.folds[0]?.userPerformance?.topics[0]
                            ?.name
                        }
                      </StyledTitle>
                      <StyledSubTitle
                        className="userprogress__subtitle"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[0]
                            ?.color
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            data?.content[0]?.folds[0]?.userPerformance
                              ?.topics[0]?.description ?? '',
                        }}
                      ></StyledSubTitle>
                    </div>
                    <div className="content__progress">
                      <div className="progress-bar">
                        <div
                          className="progress-bar__fill"
                          style={{
                            width: `${data?.content[0]?.folds[0]?.userPerformance?.topics[0]?.percent}%`,
                            backgroundColor: `${data?.content[0]?.folds[0]?.userPerformance?.topics[0]?.color}`,
                          }}
                        />
                      </div>
                      <p
                        style={{
                          color: `${data?.content[0]?.folds[0]?.userPerformance?.topics[0]?.color}`,
                        }}
                      >
                        {Math.round(
                          data?.content[0]?.folds[0]?.userPerformance?.topics[0]
                            ?.percent ?? 0,
                        )}
                        %
                      </p>
                    </div>
                  </div>
                </div>
                <div className="userprogress__item">
                  <img
                    src={`https://api-co-brt-securitymatch-dev.digitalproducts.com.co${data?.content[0]?.folds[0]?.userPerformance?.topics[1]?.img}`}
                    alt=""
                    className="userprogress__item-img"
                  />
                  <div className="userprogress__item-container">
                    <div className="userprogress__item-content">
                      <StyledTitle
                        className="userprogress__title"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[1]
                            ?.color
                        }
                      >
                        {
                          data?.content[0]?.folds[0]?.userPerformance?.topics[1]
                            ?.name
                        }
                      </StyledTitle>
                      <StyledSubTitle
                        className="userprogress__subtitle"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[1]
                            ?.color
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            data?.content[0]?.folds[0]?.userPerformance
                              ?.topics[1]?.description ?? '',
                        }}
                      ></StyledSubTitle>
                    </div>
                    <div className="content__progress">
                      <div className="progress-bar">
                        <div
                          className="progress-bar__fill"
                          style={{
                            width: `${data?.content[0]?.folds[0]?.userPerformance?.topics[1]?.percent}%`,
                            backgroundColor: `${data?.content[0]?.folds[0]?.userPerformance?.topics[1]?.color}`,
                          }}
                        />
                      </div>
                      <p
                        style={{
                          color: `${data?.content[0]?.folds[0]?.userPerformance?.topics[1]?.color}`,
                        }}
                      >
                        {Math.round(
                          data?.content[0]?.folds[0]?.userPerformance?.topics[1]
                            ?.percent ?? 0,
                        )}
                        %
                      </p>
                    </div>
                  </div>
                </div>
                <div className="userprogress__item">
                  <img
                    src={`${baseApi}${data?.content[0]?.folds[0]?.userPerformance?.topics[2]?.img}`}
                    alt=""
                    className="userprogress__item-img"
                  />
                  <div className="userprogress__item-container">
                    <div className="userprogress__item-content">
                      <StyledTitle
                        className="userprogress__title"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[2]
                            ?.color
                        }
                      >
                        {
                          data?.content[0]?.folds[0]?.userPerformance?.topics[2]
                            ?.name
                        }
                      </StyledTitle>
                      <StyledSubTitle
                        className="userprogress__subtitle"
                        fontColor={
                          data?.content[0]?.folds[0]?.userPerformance?.topics[2]
                            ?.color
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            data?.content[0]?.folds[0]?.userPerformance
                              ?.topics[2]?.description ?? '',
                        }}
                      ></StyledSubTitle>
                    </div>
                    <div className="content__progress">
                      <div className="progress-bar">
                        <div
                          className="progress-bar__fill"
                          style={{
                            width: `${data?.content[0]?.folds[0]?.userPerformance?.topics[2]?.percent}%`,
                            backgroundColor: `${data?.content[0]?.folds[0]?.userPerformance?.topics[2]?.color}`,
                          }}
                        />
                      </div>
                      <p
                        style={{
                          color: `${data?.content[0]?.folds[0]?.userPerformance?.topics[2]?.color}`,
                        }}
                      >
                        {Math.round(
                          data?.content[0]?.folds[0]?.userPerformance?.topics[2]
                            ?.percent ?? 0,
                        )}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userprogress__box evolution">
              <div className="userprogress__contenttext">
                <h2>Evolución</h2>
                {data?.content[0]?.folds[0]?.homeData?.endgame ? (
                  <p>{data?.content[0]?.folds[0]?.noGameMessage}</p>
                ) : (
                  <p>
                    Actualmente estás en
                    <span>
                      {' '}
                      nivel{' '}
                      {
                        data?.content[0]?.folds[0]?.evolutionData?.levelName
                      },{' '}
                    </span>
                    sigue jugando para aumentar tu nivel.
                  </p>
                )}
              </div>
              <div className="userprogress__content-evolution">
                <img
                  src={`https://api-co-brt-securitymatch-dev.digitalproducts.com.co${data?.content[0]?.folds[0]?.evolutionData?.avatars[0]?.image?.url}`}
                  alt=""
                  className="userprogress__content-evolution-img"
                />
                <img
                  src={`https://api-co-brt-securitymatch-dev.digitalproducts.com.co${data?.content[0]?.folds[0]?.evolutionData?.avatars[1]?.image?.url}`}
                  alt=""
                  className="userprogress__content-evolution-img"
                />
                <img
                  src={`https://api-co-brt-securitymatch-dev.digitalproducts.com.co${data?.content[0]?.folds[0]?.evolutionData?.avatars[2]?.image?.url}`}
                  alt=""
                  className="userprogress__content-evolution-img"
                />
              </div>
            </div>
          </div>
        </div>
        {menu && (
          <div className="menu">
            <div className="menu__container">
              <div className="menu__content">
                {Array.isArray(menu) &&
                  menu?.map((item, index) => (
                    <div key={index}>
                      <img
                        src={`${baseApi}${
                          currentPath === item?.page?.slug
                            ? item?.iconOn?.url
                            : item?.iconOff?.url
                        }`}
                        alt={item?.page?.slug}
                        className={`item-avatars-img ${
                          currentPath === item?.page?.slug ? 'active' : ''
                        }`}
                        onClick={() => {
                          if (item?.page?.slug) {
                            const fullUrl = `${window.location.origin}/${item?.page?.slug}`;
                            window.open(fullUrl, '_self');
                          }
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
